export interface IRules {
  rule: RegExp;
  message: string;
}

export const TRUST_PILOT_LINK = 'https://uk.trustpilot.com/review/qeeper.co.uk';
export const COLORS = {
  PRIMARY: '#f84069',
};

export const CONTACT = {
  PHONE: '0333 335 5172',
  EMAIL: 'request@qeeper.co.uk',
  TRADES_EMAIL: 'trades@qeeper.co.uk',
  FINANCE_EMAIL: 'finance@qeeper.co.uk',
};

export const JOB_REF_PREFIX = 'QE';

export const SOCAIL = {
  FACEBOOK: 'https://www.facebook.com/Qeeper.Home/',
  INSTAGRAM: 'https://www.instagram.com/qeeper_home',
  LINKEDIN: 'https://www.linkedin.com/company/qeeper',
  TWITTER: 'https://twitter.com/qeeper_home',
};

export const WEB_LINK = 'https://www.qeeper.co.uk';
export const WEB_LINK_WITHOUT = 'https://qeeper.co.uk';

export const CLIENT_ROUTE = {
  FLOW: '/booking-flow',
  JOB: '/job',
  VIEW: '/view',
  PROPERTY_MANAGERS: '/property-managers',
  SERVICES: '/services',
  HEAT_AND_GAS_SERVICES: '/services/heating-and-gas',
  APPLIANCE_REPAIR_SERVICES: '/services/appliance-repair',
};

export const HOME_ROUTE = {
  TERMS_CONDITIONS: '/terms-and-conditions',
  SERVICES_ELECTRICAL: '/services/electrical',
  SERVICES_PLUMBING: '/services/plumbing',
  SERVICES_HEAT_AND_GAS: '/services/heating-and-gas',
  SERVICES_HANDYMAN: '/services/handyman',
  SERVICES_APPLIANCE_REPAIR: '/services/appliance-repair',
};

export const QUERY = '[query]';
export interface SelectOptionProps {
  label: string;
}

export const GOOGLE_BUCKET = 'https://storage.googleapis.com/';

export const BUCKET_NAMES = {
  qeeperFiles: 'qeeper-files/',
  JOB: 'qeeper-job-files/',
};

export const BUCKET_LIST: {
  [key: string]: { qeeperFiles: string; JOB: string };
} = {
  development: {
    qeeperFiles: 'qeeper-dev/',
    JOB: 'qeeper-dev/',
  },
  production: {
    qeeperFiles: 'qeeper-files/',
    JOB: 'qeeper-job-files/',
  },
};
export const ROLE_LIST = {
  SUPERADMIN: {
    id: 1,
    name: 'SUPERADMIN',
    description: 'Super administrator - monitor financial aspects only',
  },
  ADMIN: {
    id: 2,
    name: 'ADMIN',
    description: 'Administrator - monitor daily activities',
  },
  OPS: {
    id: 3,
    name: 'OPS',
    description: 'OPS - monitor daily activities',
  },
  CLIENT: {
    id: 4,
    name: 'CLIENT',
    description: 'Client - who requests for services',
  },
  SUPPLIER: {
    id: 5,
    name: 'SUPPLIER',
    description: 'Supplier - who provides the services',
  },
};

export const ADMIN_JOB_STATUS = {
  NEW_JOB: {
    id: 100,
    title: 'New Job',
    description: 'New Job',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  WAITING_CLIENT_CONFIRM: {
    id: 200,
    title: 'Waiting on Client Confirmation ',
    description: 'Waiting on Client Confirmation ',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  AWAITING_ACCESS: {
    id: 300,
    title: 'Awaiting Access',
    description: 'Awaiting Access',
    roleId: ROLE_LIST.OPS.id,
    enabled: false,
  },
  AWAITING_PARTS: {
    id: 400,
    title: 'Awaiting Parts',
    description: 'Awaiting Parts',
    roleId: ROLE_LIST.OPS.id,
    enabled: false,
  },
  AWAITING_QUOTATION: {
    id: 500,
    title: 'Awaiting Quotation',
    description: 'Awaiting Quotation',
    roleId: ROLE_LIST.OPS.id,
    enabled: false,
  },
  SCHEDULED: {
    id: 600,
    title: 'Scheduled',
    description: 'scheduled',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  ATTENDED: {
    id: 700,
    title: 'Attended',
    description: 'Attended',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  ISSUE: {
    id: 800,
    title: 'Issue',
    description: 'Issue',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  RECALL: {
    id: 900,
    title: 'Recall',
    description: 'Recall',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  CHARGED_CANCELLATION: {
    id: 1000,
    title: 'Charged Cancellation',
    description: 'Charged Cancellation',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },

  AWAITING_TRADESPERSON_ADMIN: {
    id: 1200,
    title: 'Awaiting Tradesperson Admin',
    description: 'Awaiting Tradesperson Admin',
    roleId: ROLE_LIST.OPS.id,
    enabled: false,
  },
  JOB_REPORT_SENT: {
    id: 1300,
    title: 'Job Report Sent',
    description: 'Job Report Sent',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  ARCHIVE: {
    id: 1600,
    title: 'Archive',
    description: 'Archive',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  PARTIAL_JOB: {
    id: 1700,
    title: 'Partial Job',
    description: 'Partial Job',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  ARCHIVE_NO_SHOW: {
    id: 1900,
    title: 'Archive - No Show',
    description: 'Archive - No Show',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  COMPLETED: {
    id: 2000,
    title: 'Completed',
    description: 'Completed',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  REMEDIAL_QUOTE: {
    id: 2100,
    title: 'Remedial Quote',
    description: 'Remedial Quote',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
  REMEDIAL_QUOTE_APPROVED: {
    id: 2200,
    title: 'Remedial Quote Approved',
    description: 'Remedial Quote Approved',
    roleId: ROLE_LIST.OPS.id,
    enabled: true,
  },
};

export const INC_TIME_SLOT = 30;
export const HALF_HOUR = 30;
export const WORK_START_TIME = {
  HOURS: 5,
  MINUTES: 30,
};

export const QEEPER_ADDRESS = {
  line1: '167 City Rd',
  line2: 'London EC1V 1AW',
};

export const REVIEW_STATUS = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
};
